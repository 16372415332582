import { Injectable } from '@angular/core';
import { ToastrService, ActiveToast } from 'ngx-toastr';

@Injectable()
export class ToastrAlertsService {
  public toastRef: ActiveToast<ToastrService>;

  constructor(private toastr: ToastrService) { }

  /**
   * @description Generates a Success toaster
   * @param {string} message An assigned message
   * @param {string} title A default message '¡Listo!'
   */
  public successAlert(message: string, title = '¡Listo!'): void {
    this.toastr.success(message, title);
  }

  /**
   * @description Generates a Error toaster
   * @param {string} message An assigned message
   * @param {string} title A default message '¡Ocurrió un error!'
   */
  public errorAlert(message: string, title = 'Ocurrió un error'): void {
    this.toastr.error(message, title);
  }

  /**
   * @description Generates a Info toaster
   * @param {string} message An assigned message
   * @param {string} title A default message '¡Información!'
   */
  public infoAlert(message: string, title = 'Información'): void {
    this.toastr.info(message, title);
  }

  /**
   * @description Generates a Warning toaster
   * @param {string} message An assigned message
   * @param {string} title A default message '¡Atención!'
   */
  public warningAlert(message: string, title = '¡Atención!'): void {
    this.toastr.warning(message, title);
  }

  /**
   * @description It displays an alert with an effect of processing data.
   * Important: It needs to be closed with the closeProcessing()
   * @param message A default message that can be overwritten
   * @param title A default title that can be overwritten
   */
  public processingAlert(message: string = 'Estamos procesando la información', title: string = 'Procesando'): number {
    const messageHtml = '<div class=\"toast--loading\">' + message + '<span>.</span><span>.</span><span>.</span></div>';
    this.toastRef = this.toastr.info(messageHtml, title, {
      disableTimeOut: true,
      tapToDismiss: false
    });
    return this.toastRef.toastId;
  }

  /**
   * @description Closes processing toastr by toastId given or if undefined closes latest created toast
   * @param { number } toastId ToastId to close * Optional parameter
   */
  public closeProcessing(toastId?: number) {
    if (toastId) {
      this.toastr.clear(toastId);
    } else {
      this.toastr.clear(this.toastRef.toastId);
    }
  }

}
