import { WaybillResponse } from 'src/app/interfaces/waybill-response';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';

const apiToken = environment.apiToken;
const courierApi = environment.courierApiUrl;
const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': apiToken});

@Injectable()
export class CourierProvider {

  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Get courier guides by order
   * @param {string} tenantId The tenantId to find
   * @param {string} folio The order's folio
   * @returns {Array<WaybillResponse>} Observable with credential list
   */
   public getCourierGuidesByOrder(tenantId: string, folio: string): Promise<Array<WaybillResponse>> {
    const url = `${courierApi}/general/tenantId/${tenantId}/order/${folio}`;
    return this.http.get<Array<WaybillResponse>>(url, {'headers': headers}).toPromise();
  }

}
