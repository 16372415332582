import { Component, Input, OnChanges, OnInit } from '@angular/core';

import { environment } from '../../../environments/environment';
import {
  FileResponse,
  Order,
  ProofOfDeliveryTags,
  ShipmentRequest
} from '../../interfaces';
import { OrderProvider } from '../../providers/orders/order-provider.service';
import { ProofOfDeliveryLabels } from './proof-delivery.labels';
import { ShipperProvider } from '../../providers/shipper/shipper-provider.service';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

const DETAIL_PARAM = 'detail';
const LOGO_URL ='logoUrl';
const EMPTY_STRING = '';
const KEY_SIGNATURE = 'imgSignature'
const SYSTEM_DEFAULT_LOGO = 'assets/icons/solutions/tep-logo.svg';

@Component({
  selector: 'app-proof-delivery',
  templateUrl: './proof-delivery.component.html',
  styleUrls: ['./proof-delivery.component.scss']
})
export class ProofDeliveryComponent implements OnInit, OnChanges {

  @Input() orderData: Order;
  @Input() shipment: ShipmentRequest;

  public labels: ProofOfDeliveryTags;
  public realDateDelivery: Date;
  public srcImg: string;
  public timestamp: Date;

  constructor(
    private orderProvider: OrderProvider,
    private shipperProvider: ShipperProvider,
    private toast: ToastrAlertsService,
  ) {
    this.labels = ProofOfDeliveryLabels;
  }

  /**
   * @description Init POD data
   */
  public async ngOnInit(): Promise<void> {
    this.matchDetailsWithOrder();
    const timestampOrder = this.orderData.detail.evidencias.firma.timestampCaptura;
    this.realDateDelivery = timestampOrder ? timestampOrder : this.shipment.fechaEntregaReal;
    this.timestamp = new Date();
    this.srcImg = await this.getLogo();
  }

  /**
   * @description Matchs details shipment request with current order
   */
  public async matchDetailsWithOrder(): Promise<void> {
    const details = this.shipment.detalles;
    for (const detail of details) {
      for (const orderData of detail.documentos) {
        if (orderData === this.orderData._id) {
          this.orderData[DETAIL_PARAM] = detail;
          if (this.orderData.detail.evidencias.firma.sinFirma) {
            this.orderData[KEY_SIGNATURE] = EMPTY_STRING;
          } else {
            this.orderData[KEY_SIGNATURE] = await this.getSignatureFile(this.orderData.detail.evidencias.firma.img);
          }
        }
      }
    }
  }

  /**
   * @description Get signature file
   * @param imageOid File Object Id
   * @returns URL of image
   */
  public async getSignatureFile(imageOid): Promise<string> {
    if (imageOid) {
      const fileBody: FileResponse = await this.orderProvider.getFile(imageOid);
      return fileBody.archivoURL;
    }
    return EMPTY_STRING;
  }

  /**
   * @description Listening for any view chnages
   */
  public ngOnChanges(): void {
    this.ngOnInit();
  }

  /**
   * @description Get logo for the POD header
   * @returns {string} Logo path
   */
  public async getLogo(): Promise<string> {
    try {
      const shipper = await this.shipperProvider.getShipperByOid(this.orderData.shipperId);
      const fileNameUrl = shipper.configuracion[LOGO_URL] ? shipper.configuracion[LOGO_URL] : EMPTY_STRING;
      let logo = environment.baseStorageUrl + environment.mainContainer;

      if (fileNameUrl && fileNameUrl !== EMPTY_STRING) {
        logo += fileNameUrl;
      } else {
        logo = SYSTEM_DEFAULT_LOGO;
      }

      return logo;
    } catch (error) {
      this.toast.errorAlert(ProofOfDeliveryLabels.errorGettingShipperLogo);
    }
  }

}
