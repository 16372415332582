import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

import {
  DialogFilePreview,
  EvidenceFolio,
  EvidenceHistoryDetail,
  IncidenceData,
  ShipmentRowExtended,
  ShipmentRequestResponse
} from '../../../interfaces';
import { DialogFilePreviewConst, ResourceType } from './dialog-file-preview.constants';
import { DialogFilePreviewLabels } from './dialog-file-preview.labels';
import { DownloadFilesService } from '../../../services/utils/download-file.service';
import { FileConversorService } from '../../../services/utils/file-conversor.service';
import { ShowFullImageComponent } from '../show-full-image/show-full-image.component';
import { ToastrAlertsService } from '../../../services/utils/toastr-alerts.service';

import * as jsPDF from 'jspdf';
import html2canvas from 'html2canvas';
@Component({
  selector: 'app-dialog-file-preview',
  templateUrl: './dialog-file-preview.component.html',
  styleUrls: ['./dialog-file-preview.component.scss', '../../../app.component.scss'],
  providers: [DownloadFilesService]
})
export class DialogFilePreviewComponent implements OnInit {
  public additionalFunctions: boolean;
  public additionalInformation: boolean;
  public evidenceDate: string;
  public evidenceFolio: string;
  public evidenceFolioData: EvidenceFolio;
  public evidenceOrigin: string;
  public evidenceReceptor: string;
  public evidenceReleaseFolioData: EvidenceFolio;
  public evidenceShipment: string;
  public incidenceData: IncidenceData;
  public isDownloadable: boolean;
  public isEvidenceInfo: boolean;
  public isPDF: boolean;
  public isPrintable: boolean;
  public labels: DialogFilePreview;
  public reader: FileReader;
  public resourceType: number;
  public shipmentRequestData: ShipmentRequestResponse;
  public shipmentToLoadPlan: ShipmentRowExtended;
  public showIncidence: boolean;
  public url: string | object;

  private angle: number;
  private formatFile: string;
  private scale: number;
  private isImageRotated: boolean;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialog: MatDialog,
    private downloadService: DownloadFilesService,
    private sanitizer: DomSanitizer,
    private toast: ToastrAlertsService,
    private renderer: Renderer2,
    public dialogRef: MatDialogRef<DialogFilePreviewComponent>,
    private fileCService: FileConversorService
  ) { this.labels = DialogFilePreviewLabels; }

  /**
   * @description Event fires when init component
   */
  public ngOnInit(): void {
    this.angle = 0;
    this.scale = 1;
    this.isEvidenceInfo = this.data.isEvidenceInfo ? this.data.isEvidenceInfo : false;
    this.isImageRotated = false;
    this.evidenceOrigin = '';
    this.evidenceFolio = '';
    this.evidenceReceptor = '';
    this.evidenceShipment = '';
    this.evidenceDate = '';
    this.defineResourceType();
    if (this.data.displayInfo) {
      if (this.data.incidenceInfo && this.data.incidenceInfo.isIncidence) {
        this.showIncidence = true;
        this.incidenceData = this.data.incidenceInfo.incidence;
      } else {
        this.showIncidence = false;
      }
      if (!this.showIncidence) {
        this.additionalInformation = true;
        this.setAdditionalInfo();
      }
    }
  }

  /**
   * @description set the additional information that will be displayed in the dialog
   */
  public setAdditionalInfo(): void {
    if (this.data.evidenceInfoV3) {
      this.evidenceOrigin = this.data.evidenceInfoV3.createdBy.module;
      this.evidenceFolio = this.data.evidenceInfoV3.folio;
      this.evidenceReceptor = this.data.evidenceInfoV3.receptor;
      this.evidenceShipment = this.data.evidenceInfoV3.shipmentId;
      this.evidenceDate = this.data.evidenceInfoV3.registerDate;
    } else {
      this.evidenceOrigin = this.data.evidenceInfo.evidenceOrigin;
      this.evidenceFolio = this.data.evidenceInfo.folio ? this.data.evidenceInfo.folio : DialogFilePreviewConst.NO_INFO;
      this.evidenceReceptor = this.data.evidenceInfo.receptor ? this.data.evidenceInfo.receptor : DialogFilePreviewConst.NO_INFO;
      this.evidenceShipment = this.data.evidenceInfo.shipment ? this.data.evidenceInfo.shipment : DialogFilePreviewConst.NO_INFO;

      if (this.data.evidenceInfo.evidenceOrigin === DialogFilePreviewConst.ORIGIN_EVIDENCE_CONTROL) {
        this.evidenceDate = this.data.evidenceInfo.timestamp ? this.data.evidenceInfo.timestamp : DialogFilePreviewConst.NO_INFO;
      } else {
        const evidences: Array<EvidenceHistoryDetail> = this.data.evidenceInfo.evidence;
        const evidenceFiltered = evidences.filter(el => {
          return el.evidence.img.nombre === this.data.file.name;
        });
        if (evidenceFiltered.length) {
          this.evidenceDate = evidenceFiltered[0].evidence.timestampCaptura;
        } else {
          this.evidenceDate = DialogFilePreviewConst.NO_INFO;
        }
      }
    }
  }

  /**
   * @description Event fires when click on download image button
   */
  public onDownloadImage(): void {
    try {
      const fileName = DialogFilePreviewConst.FILE_NAME;
      const imageFile: File = this.data.file;
      this.downloadService.downloadFile(imageFile, fileName);
    } catch (error) {
      this.toast.errorAlert(this.labels.errorDownloadFile);
    }
  }

  /**
   * @description Close preview view file
   */
  public onClickClose(): void {
    if (!this.isImageRotated) {
      this.dialogRef.close(DialogFilePreviewConst.CONFIRM);
    } else {
      this.toast.processingAlert(DialogFilePreviewLabels.savingChanges);
      this.saveImage();
    }
  }

  /**
   * @description Starts the process to download selected file
   */
  public onDownload(): void {
    (document.getElementById(DialogFilePreviewConst.DOWNLOADBUTTON) as HTMLButtonElement).disabled = true;
    const data = document.getElementById(DialogFilePreviewConst.PRINTSECTION);
    html2canvas(data, {
      width: DialogFilePreviewConst.MAXWIDTHPDF,
      scale: DialogFilePreviewConst.PDFSCALE
    }).then(canvas => {
      const contentDataURL = canvas.toDataURL(DialogFilePreviewConst.DATAURLFORMAT, DialogFilePreviewConst.QUALITYIMAGE);
      const pdf = jsPDF(DialogFilePreviewConst.PAGEORIENTATION, DialogFilePreviewConst.PDFMEASUREMENTUNIT,
        DialogFilePreviewConst.PAGESIZE);
      const imgHeight = canvas.height * DialogFilePreviewConst.IMGWIDTH / canvas.width;
      let heightLeft = imgHeight;
      let position = DialogFilePreviewConst.PDFHORIZONTALALIGN;

      pdf.addImage(contentDataURL, DialogFilePreviewConst.IMAGEFORMAT, DialogFilePreviewConst.PDFVERTICALALIGN, position,
        DialogFilePreviewConst.IMGWIDTH, imgHeight + DialogFilePreviewConst.PDF_HEIGHT_ADDED);
      heightLeft -= DialogFilePreviewConst.PAGEHEIGHT;

      while (heightLeft >= DialogFilePreviewConst.ZERO) {
        position = heightLeft - imgHeight;
        pdf.addPage();
        pdf.addImage(contentDataURL, DialogFilePreviewConst.IMAGEFORMAT, DialogFilePreviewConst.PDFVERTICALALIGN, position,
          DialogFilePreviewConst.IMGWIDTH, imgHeight + DialogFilePreviewConst.PDF_HEIGHT_ADDED);
        heightLeft -= DialogFilePreviewConst.PAGEHEIGHT;
      }
      pdf.save(DialogFilePreviewLabels.pdfPrefix + this.shipmentToLoadPlan.shipmentId + DialogFilePreviewConst.DOWNLOADPDFEXTENSION);
      (document.getElementById(DialogFilePreviewConst.DOWNLOADBUTTON) as HTMLButtonElement).disabled = false;
    });
  }

  /**
   * @description Rotates image in dialog
   * @param direction direction to be rotated on fixed angles of 45 degrees
   */
  public rotateImage(direction: string): void {
    this.angle += direction === DialogFilePreviewConst.LEFT ? -DialogFilePreviewConst.DEGREES : DialogFilePreviewConst.DEGREES;
    this.isImageRotated = true;
    this.renderer.setStyle(document.querySelector(DialogFilePreviewConst.IMAGEPREVIEW), DialogFilePreviewConst.TRANSFORM,
      `scale(${this.scale}) rotate(${this.angle}deg)`);
  }

  /**
   * @description Zoom image
   * @param zoom whether the zoom will be in or out
   */
  public zoom(zoom: string): void {
    if (zoom === DialogFilePreviewConst.OUT && this.scale <= DialogFilePreviewConst.SCALEMIN) {
      this.scale = DialogFilePreviewConst.SCALEMINVALID;
    } else if (zoom === DialogFilePreviewConst.IN && this.scale >= DialogFilePreviewConst.SCALEMAX) {
      this.scale = DialogFilePreviewConst.SCALEMAXVALID;
    }
    this.scale += zoom === DialogFilePreviewConst.OUT ? -DialogFilePreviewConst.SCALEADD : DialogFilePreviewConst.SCALEADD;
    this.renderer.setStyle(document.querySelector(DialogFilePreviewConst.IMAGEPREVIEW), DialogFilePreviewConst.TRANSFORM,
      `scale(${this.scale}) rotate(${this.angle}deg)`);
  }

  /**
   *@description Deploy modal for show full image
   *@returns void
   */
  public openImage(): void {
    const dialogImage = this.dialog.open(ShowFullImageComponent, {
      data: {
        image: this.url,
        isRotatable: true
      },
      width: DialogFilePreviewConst.WIDTH_MODAL,
      height: DialogFilePreviewConst.HEIGHT_MODAL,
      maxWidth: DialogFilePreviewConst.MAX_WIDTH_MODAL,
      panelClass: DialogFilePreviewConst.MODAL_CLASS
    });

    dialogImage.afterClosed().subscribe(async result => {
      if (result !== DialogFilePreviewConst.CLOSE_MODAL) {
        this.isImageRotated = true;
        this.reader.readAsDataURL(result);
        this.reader.onload = () => {
          this.formatFile = result.type;
          this.url = this.reader.result;
          this.isPDF = false;
        };
      }
    });
  }

  /**
   * @description Opens modal to view full image pdf.
   */
  public expandPDF(): void {
    this.dialog.open(ShowFullImageComponent, {
      data: {
        pdfFile: this.data.file,
        isPDF: true
      },
      width: DialogFilePreviewConst.WIDTH_MODAL,
      height: DialogFilePreviewConst.HEIGHT_MODAL,
      maxWidth: DialogFilePreviewConst.MAX_WIDTH_MODAL,
      panelClass: DialogFilePreviewConst.MODAL_CLASS
    });
  }

  /**
   * @description Convert edited image to url data to save image
   */
  private saveImage() {
    const data = document.getElementById(DialogFilePreviewConst.IMAGEPREVIEWDIALOG);
    this.renderer.setStyle(document.querySelector(DialogFilePreviewConst.IMAGEPREVIEW), DialogFilePreviewConst.TRANSFORM,
      `scale(1) rotate(${this.angle}deg)`);
    html2canvas(data).then(canvas => {
      canvas.toBlob((blob) => {
        this.dialogRef.close(blob);
      }, this.formatFile, DialogFilePreviewConst.QUALITYIMAGE);
    });
  }

  /**
   * @description Determinate which type of file will be display
   */
  public defineResourceType(): void {
    switch (true) {
      case this.data.hasOwnProperty(DialogFilePreviewConst.KEY_SHIPMENT_TO_LOAD_PLAN) === true: {
        this.additionalFunctions = true;
        this.resourceType = ResourceType.LOAD_PLAN;
        this.shipmentToLoadPlan = this.data.shipmentToLoadPlan;
        this.isDownloadable = this.data.isDownloadable;
        this.isPrintable = this.data.isPrintable;
        break;
      }
      case this.data.hasOwnProperty(DialogFilePreviewConst.KEY_EVIDENCE_RELEASE_FOLIO_DATA) === true &&
        this.data.evidenceReleaseFolioData.folio.startsWith(DialogFilePreviewConst.EVIDENCE_IDENTIFIER): {
        this.additionalFunctions = true;
        this.resourceType = ResourceType.EVIDENCE_DOC;
        this.evidenceFolioData = this.data.evidenceReleaseFolioData;
        this.isDownloadable = false;
        this.isPrintable = true;
        break;
      }
      case this.data.hasOwnProperty(DialogFilePreviewConst.KEY_EVIDENCE_RELEASE_FOLIO_DATA) === true: {
        this.additionalFunctions = true;
        this.resourceType = ResourceType.EVIDENCE_RELEASE_DOC;
        this.evidenceReleaseFolioData = this.data.evidenceReleaseFolioData;
        this.isDownloadable = this.data.isDownloadable;
        this.isPrintable = this.data.isPrintable;
        break;
      }
      case this.data.hasOwnProperty(DialogFilePreviewConst.KEY_SHIPMENT_REQUEST_DATA) === true: {
        this.additionalFunctions = true;
        this.resourceType = ResourceType.SHIPMENT_REQUEST_DOC;
        this.shipmentRequestData = this.data.shipmentRequestData;
        this.isDownloadable = this.data.isDownloadable;
        this.isPrintable = this.data.isPrintable;
        break;
      }
      default : {
        this.additionalFunctions = false;
        this.resourceType = ResourceType.IMAGE_OR_PDF;
        this.isDownloadable = false;
        this.isPrintable = false;
        this.reader = new FileReader();
        this.isPDF = false;

        this.reader.readAsDataURL(this.data.file);
        this.reader.onload = () => {
          if (this.data.file.type === DialogFilePreviewConst.PDFEXTENSION) {
            const base64 = this.reader.result.toString();
            const blob = new Blob([this.fileCService.base64toBlob(base64)], { type: DialogFilePreviewConst.MIME_PDF });
            const fileURL = URL.createObjectURL(blob);
            this.url = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
            this.isPDF = true;
          } else {
            this.formatFile = this.data.file.type;
            this.url = this.reader.result;
            this.isPDF = false;
          }
        };
        this.reader.onerror = () => {
          this.toast.errorAlert(DialogFilePreviewLabels.error);
        };
        break;
      }
    }
  }
}
