import { Routes, RouterModule} from '@angular/router';

import { OrderHistoricClientComponent } from './pages/orders/order-historic-client/order-historic-client.component';
import { PagesComponent } from './pages.component';
import { SearchOrderClientComponent } from './pages/orders/search-order-client/search-order-client.component';

const routes: Routes = [
  { path: '', component: PagesComponent, children: [
    { path: 'search-order', component: SearchOrderClientComponent },
    { path: 'search-order/order-tracking', component: OrderHistoricClientComponent },
    { path: '', redirectTo: '/search-order', pathMatch: 'full' },
  ] }
];

export const routing = RouterModule.forRoot (routes, {
  enableTracing: false
});
