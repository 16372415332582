import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class BlobProviderService {

  constructor() { }

  /**
   * @description Gets MIME type for a file
   * @param {string} MIMEType Extension current file
   * @returns {string} A MIME type assigned
   */
  public async getMIMEtype(MIMEType: string): Promise<string> {
    let result = '';
    switch (MIMEType) {
      case 'jpg':
        result = 'image/jpeg';
      break;
      case 'jpeg':
        result = 'image/jpeg';
      break;
      case 'pdf':
        result = 'application/pdf';
      break;
      case 'png':
        result = 'image/png';
      break;
      default:
        result = 'application/octet-binary';
    }

    return result;
  }
}
