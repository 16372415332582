import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from './../../../environments/environment';
import { Shipper } from '../../interfaces';

const apiToken = environment.apiToken;
const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': apiToken});
const coreApiUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root'
})
export class ShipperProvider {

  constructor(
    private http: HttpClient,
  ) { }

  /**
   * @description This function returns a shipper
   * @param {string} shipperOid Shipper to search
   * @return {Shipper} Shipper found
  **/
  public async getShipperByOid(shipperOid: string): Promise<Shipper> {
    return await this.http.get<Shipper>(coreApiUrl + '/embarcadores/' +  shipperOid, {'headers': headers})
    .toPromise();
  }
}