import { AfterViewInit, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

import { FileConversorService } from './../../../services/utils/file-conversor.service';

import Cropper from 'cropperjs';

const CLOSE_MODAL = 'Closed';
const DEGREES = 45;
const DOT = '.';
const DRAG_MODE = 'move';
const LEFT = 'left';
const MIME_PDF = 'application/pdf';
const QUALITY_IMAGE = 1.0;
const ZOOM_IN = 0.1;
const ZOOM_OUT = -0.1;

@Component({
  selector: 'app-show-full-image',
  templateUrl: './show-full-image.component.html',
  styleUrls: ['./show-full-image.component.scss']
})
export class ShowFullImageComponent implements OnInit, AfterViewInit {
  @ViewChild('image', { static: false })

  private cropper: Cropper;
  public formatFile: string;
  public imageElement: ElementRef;
  public imageSource: string;
  public isEdited: boolean;
  public isPDF: boolean;
  public isRotatable: boolean;
  public pdfUrl: SafeResourceUrl;
  public reader: FileReader;

  constructor(
    public dialogRef: MatDialogRef<ShowFullImageComponent>,
    public dialog: MatDialog,
    private fileCService: FileConversorService,
    private sanitizer: DomSanitizer,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  /**
   *@description Assigns the selected image
   *@returns void
   **/
  public ngOnInit(): void {
    this.imageSource = this.data.image;
    this.isEdited = false;
    this.isPDF = this.data.isPDF ? this.data.isPDF : false;
    if (!this.isPDF) {
      this.formatFile = this.getFormatFileFromUrlString(this.imageSource);
      this.isRotatable = this.data.isRotatable ? this.data.isRotatable : false;
    } else {
      this.reader = new FileReader();
      this.reader.readAsDataURL(this.data.pdfFile);
      this.reader.onload = () => {
        const base64 = this.reader.result.toString();
        const blob = new Blob([this.fileCService.base64toBlob(base64)], { type: MIME_PDF });
        const fileURL = URL.createObjectURL(blob);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(fileURL);
      };
    }
  }

  /**
  *@description Initialize and configure the plugin settings to zoom the image
  *@returns void
  **/
  public ngAfterViewInit(): void {
    if (!this.isPDF) {
      const img = this.imageElement.nativeElement;
      const options = {
        zooable: true,
        zoomOnTuch: true,
        scalabre: true,
        aspectRatio: 1,
        crop: () => {
          this.cropper.clear();
          this.cropper.setDragMode(DRAG_MODE);
        },
        background: false
      };
      this.cropper = new Cropper(img, options);
    }
  }

  /**
  *@description Zoom in on the image
  *@returns void
  **/
  public zoomIn(): void {
    this.cropper.zoom(ZOOM_IN);
  }

  /**
   *@description Do zoom out at the image
   *@returns void
   */
  public zoomOut(): void {
    this.cropper.zoom(ZOOM_OUT);
  }

  /**
   * @description Rotates image
   * @param {string} direction direction to rotate image
   */
  public rotateImage(direction: string): void {
    this.isEdited = true;
    const angle = direction === LEFT ? -DEGREES : DEGREES;
    this.cropper.rotate(angle);
  }

  /**
   *@description Close the modal that show the image
   *@returns void
   */
  public onClickClose(): void {
    if (this.isEdited) {
      this.onSaveChanges();
    } else {
      this.dialogRef.close(CLOSE_MODAL);
    }
  }

  /**
   * @description Handle save changes event
   */
  private onSaveChanges(): void {
    this.cropper.getCroppedCanvas().toBlob((blob) => {
      this.dialogRef.close(blob);
    }, this.formatFile, QUALITY_IMAGE);
  }

  /**
   * @description Get format file url string
   * @param {string} urlString url string
   * @returns Format file string
   */
  private getFormatFileFromUrlString(urlString: string): string {
    return urlString.split(DOT).pop();
  }
}
