export enum SearchOrderClientComponentLabels {
  invalid = ' inválido',
  maxLenthOrderId = 'El folio de la orden no puede ser mayor a 25 caracteres',
  orderFolio = 'Folio de la orden',
  required = ' requerido',
  search = 'Buscar',
  searchOrder = 'Busca tu orden',
  searchOrderIdentifier = 'El folio de la orden es'
}

export enum SearchOrderLabels {
  errorGettingOrder = 'Error al consultar la orden',
  notFoundOrder = 'No se encontró información para los criterios ingresados, verifica los datos e intenta de nuevo',
}