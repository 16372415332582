<h3 class="title">{{ labels.title }}
  <img
    [src]="srcImg"
    alt="{{ labels.title }}"
    class="img-logo"/>
</h3>
<p>{{ labels.dateParagraph }} {{ timestamp | date: 'dd/MMMM/yyyy' | titlecase }}</p>
<p>{{ labels.presentationParagraph }}</p>
<p>{{ labels.shipmentParagraph }} {{ shipment.id }}</p>

<div class="margin-subtitle">
  <mat-divider class="mat-divider"></mat-divider>
  <strong class="subtitle-header">{{ labels.generalInformationSubtitle }}</strong>
  <mat-divider class="mat-divider"></mat-divider>
</div>
<table class="content-table" aria-describedby="generalInfoTable">
  <tr>
    <th id="carrierParagraph">{{ labels.carrierParagraph }}</th><td>{{ shipment.embarque.lineaTransporte.nombre }}</td>
    <th id="appointmentDateParagraph">{{ labels.appointmentDateParagraph }}</th><td>{{ shipment.fechaCarga | date:'dd-MMMM-yyyy' | titlecase }} {{ shipment.fechaCarga | date:'hh:mm a' }}</td>
  </tr>
  <tr>
    <th id="tripStateParagraph">{{ labels.tripStateParagraph }}</th><td>{{ shipment.estatus }}</td>
    <th id="tripTypeParagraph">{{ labels.tripTypeParagraph }}</th><td>{{ shipment.tipoViaje }}</td>
  </tr>
  <tr>
    <th id="vehicleTypeParagraph">{{ labels.vehicleTypeParagraph }}</th><td>{{ shipment.unidadTransporte.nombre }}</td>
    <th id="weightParagraph">{{ labels.weightParagraph }}</th><td>{{ shipment.totalPeso }}</td>
  </tr>
  <tr>
    <th id="volumeParagraph">{{ labels.volumeParagraph }} (m<sup>3</sup>)</th><td>{{ shipment.totalVolumen }}</td>
    <th id="palletsParagraph">{{ labels.palletsParagraph }}</th><td>{{ shipment.totalTarimas }}</td>
  </tr>
  <tr>
    <th id="boxesParagraph">{{ labels.boxesParagraph }}</th><td>{{ shipment.totalCajas }}</td>
    <th id="piecesParagraph">{{ labels.piecesParagraph }}</th><td>{{ shipment.totalPiezas }}</td>
  </tr>
  <tr>
    <th id="vehiclePlatesParagraph">{{ labels.vehiclePlatesParagraph }}</th><td>{{ shipment.embarque.vehiculo.placas }}</td>
    <th id="driverParagraph">{{ labels.driverParagraph }}</th><td>{{ shipment.embarque.chofer.nombre }}
    {{ shipment.embarque.chofer.appaterno }}</td>
  </tr>
  <tr>
    <th id="originParagraph">{{ labels.originParagraph }}</th><td>{{ orderData.detail.origen.municipio }}, {{ orderData.detail.origen.estado }}</td>
  </tr>
</table>
<div class="margin-subtitle">
  <mat-divider class="mat-divider"></mat-divider>
  <strong class="subtitle-header">{{ labels.deliveryInformationSubtitle }}</strong>
  <mat-divider class="mat-divider"></mat-divider>
</div>
<table class="content-table" aria-describedby="deliveryInfoTable">
  <tr>
    <th id="statusDeliveryParagraph">{{ labels.statusDeliveryParagraph }}</th><td>{{ orderData.detail.estatus }}</td>
    <th id="deliveryDateParagraph">{{ labels.deliveryDateParagraph }}</th>
    <td>{{ realDateDelivery | date:'dd-MMMM-yyyy' | titlecase }} {{ realDateDelivery | date:'hh:mm a' }}</td>
  </tr>
  <tr>
    <th id="deliveryDateEstimated">{{ labels.deliveryDateEstimated }}</th>
    <td >{{ orderData?.deliveryDate | date:'dd-MMMM-yyyy' | titlecase }} {{ orderData.appointmentHour ?  orderData.appointmentHour : '' }}</td> 
  </tr>
  <tr>
    <th id="destinationParagraph">{{ labels.destinationParagraph }}</th>
    <td>{{ orderData.detail.destino.municipio }}, {{ orderData.detail.destino.estado }}</td>
  </tr>
  <tr>
    <th id="deliveryParagraph">{{ labels.deliveryToParagraph }}</th>
    <td>{{ (orderData.detail.evidencias.firma.nombre)? orderData.detail.evidencias.firma.nombre :
    labels.noName }}</td>
  </tr>
  <tr>
    <th id="signatureParagraph">{{ labels.signatureParagraph }}</th>
    <td>
      <h3 *ngIf="orderData.detail.evidencias.firma.sinFirma" class="title">{{ labels.noSignature }}</h3>
      <img
        class="img-signature"
        *ngIf="orderData.detail.evidencias.firma.img"
        src="{{ orderData.imgSignature }}"
        alt="Signature"/>
    </td>
  </tr>
</table>

<div class="footer">
  <mat-divider class="mat-divider"></mat-divider>
    {{ labels.messageFooter }}
</div>
