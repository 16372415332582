import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { DownloadEvidencesFontSizes } from '../../enums/download-evidences-font-size';
import {
  EvidenceToDisplay,
  MassiveEvidences,
  OrderData,
  PDFEvidenceContent,
  PDFEvidenceLabels,
  PDFEvidenceSection,
  PdfFromImage,
  PictureData,
  Point,
  ShipmentRequest
} from '../../interfaces';
import { PDFLABELS_CONSTANTS } from './pdf-labels.constants';

import * as FileSaver from 'file-saver';
import * as jsPDF from 'jspdf';
import * as JSZip from 'jszip';
import { clone as _clone } from 'lodash';

const A_TAG = 'a';
const BLOB = 'blob';
const BOLD_FONT_STYLE = 'Bold';
const COLON_SYMBOL = ':';
const COMPRESSIONSTYLE = 'DEFLATE';
const DASH = '-';
const DOT = '.';
const EVIDENCE = 'Evidencia';
const FONT_SIZE = 8;
const HEIGHT_IMAGE = 160;
const HEIGHT_POSITION = 20;
const HELVETIC_FONT_FAMILY = 'Helvetica';
const INCIDENCE = 'Incidencia';
const JPEG = 'jpeg';
const JPG = 'jpg';
const LINE_BREAK = 5;
const MAX_TEXT_WIDTH = 138;
const MOUSE_CLICK_EVENT = 'click';
const MOUSE_EVENT = 'MouseEvents';
const NORMAL_FONT_STYLE = '';
const ONE = 1;
const PADDING = 2;
const PDF = 'pdf';
const PDFFILETYPE = 'application/pdf';
const SLASH = '/';
const TEXT_FILE_FORMAT = 'text/plain';
const THREE = 3;
const WIDTH_IMAGE = 180;
const WIDTH_POSITION = 15;
const ZIP = 'zip';

@Injectable()
export class DownloadFilesService {

  constructor(private http: HttpClient) {}

  /**
   * @param ordersWithEvidences Array of MassiveEvidences: Array that contains the information a any orders and their evidences/POD
   * @param fileName string: The name of resultant ZIP file
   * @description Download a massive order with evidences
   */
  public async downloadMassiveEvidences(ordersWithEvidences: Array<MassiveEvidences>, fileName: string): Promise<void> {
    if (ordersWithEvidences === undefined || ordersWithEvidences.length === 0) {
      throw new Error('Invalid parameter');
    }
    const zip = new JSZip();
    for (const order of ordersWithEvidences) {
      let consecutive = 1;
      const imageEvidences: Array<EvidenceToDisplay> = [];
      for (const evidence of order.evidences) {
        if (evidence.file.type !== PDFFILETYPE) {
          imageEvidences.push(evidence);
        }
      }
      for (let i = 0; i < order.evidences.length; i++) {
        if (order.evidences[i].file.type === PDFFILETYPE) {
          const extension = this.getExtensionFromName(order.evidences[i].name);
          const MIMEType = this.getMIMEtype(extension);
          const blob = new Blob([order.evidences[i].file], { type: MIMEType });
          zip.file(order.orderName + SLASH + EVIDENCE + DASH + consecutive + DOT + extension, blob,
            { binary: true, compression: COMPRESSIONSTYLE });
          consecutive++;
        }
      }
      for (let i = 0; i < imageEvidences.length; i++) {
        const pdfFromImage = await this.convertImagesToPDF(imageEvidences[i]);
        zip.file(order.orderName + SLASH + EVIDENCE + DASH + consecutive + DOT + PDF, pdfFromImage.blob,
          { binary: true, compression: COMPRESSIONSTYLE });
        consecutive++;
      }
    }
    zip.generateAsync({ type: BLOB }).then(function (blob) {
      FileSaver.saveAs(blob, fileName + DOT + ZIP);
    });
  }

  /**
   * @param orderName string: name of the order and also the name of the zip file
   * @param evidences Array of EvidenceToDisplay: Array that contains the evidences
   * @param incidence Wether is incidence or not
   * @description Method that automatic downloads a ZIP file with evidences/POD of an order
   */
  public async downloadEvidences(orderName: string, evidences: Array<EvidenceToDisplay>, incidence?: boolean): Promise<void> {
    const zip = new JSZip();
    const evidenceType = incidence ? INCIDENCE : EVIDENCE;
    let consecutive = 1;
    const imageEvidences: Array<EvidenceToDisplay> = [];
    for (const evidence of evidences) {
      if (evidence.file.type !== PDFFILETYPE) {
        imageEvidences.push(evidence);
      }
    }
    for (let i = 0; i < evidences.length; i++) {
      if (evidences[i].file.type === PDFFILETYPE) {
        const extension = this.getExtensionFromName(evidences[i].name);
        const MIMEType = this.getMIMEtype(extension);
        const blob = new Blob([evidences[i].file], { type: MIMEType });
        zip.file(evidenceType + DASH + consecutive + DOT + extension, blob,
          { binary: true, compression: COMPRESSIONSTYLE });
        consecutive++;
      }
    }
    for (let i = 0; i < imageEvidences.length; i++) {
      const pdfFromImage = await this.convertImagesToPDF(imageEvidences[i]);
      zip.file(evidenceType + DASH + consecutive + DOT + PDF, pdfFromImage.blob,
        { binary: true, compression: COMPRESSIONSTYLE });
      consecutive++;
    }

    zip.generateAsync({ type: BLOB }).then(function (blob) {
      FileSaver.saveAs(blob, orderName + DOT + ZIP);
    });
  }

  /**
   * @description Converts images to PDF files
   * @param {EvidenceToDisplay} image Image to convert
   * @returns {PdfFromImage} Image converted to PDF file
   */
  private async convertImagesToPDF(image: EvidenceToDisplay): Promise<PdfFromImage> {
    const doc = new jsPDF();
    doc.setFontSize(12);
    const pdfFromImage: PdfFromImage = {
      name: '',
      blob: null
    };
    let extension = this.getExtensionFromName(image.name);
    const MIMEType = this.getMIMEtype(extension);
    const blob = new Blob([image.file], { type: MIMEType });
    const b64 = await this.convertoTob64(blob);
    if (extension === JPG) {
      extension = JPEG;
    }
    doc.addImage(b64, extension.toUpperCase(), WIDTH_POSITION, HEIGHT_POSITION, WIDTH_IMAGE, HEIGHT_IMAGE);
    const resultBlob = new Blob([doc.output(BLOB)], { type: PDFFILETYPE });
    pdfFromImage.name = image.name;
    pdfFromImage.blob = resultBlob;

    return pdfFromImage;
  }

  /**
   * @description Converts files to Base 64
   * @param {Blob} blob Blob's file
   * @returns {string} File converted to base 64
   */
  private async convertoTob64(blob: Blob): Promise<string> {
    return new Promise((resolve, reject) => {
      let b64 = '';
      const reader = new FileReader();
      reader.onloadend = function() {
        b64 = reader.result.toString();
        resolve(b64);
      };
      reader.readAsDataURL(blob);
    });
  }

  /**
   * @description Add section of content to pdf document
   * @param {jsPDF} doc pdf document
   * @param {PDFEvidenceSection} data data to add pdf document
   */
  private addSectionData(doc: jsPDF, data: PDFEvidenceSection): Point {
    const maxDimensions: Point = { xPosition: 0, yPosition: 0};
    const position = _clone(data.position);
    const noInfo = PDFLABELS_CONSTANTS.noInfo;

    data.content.forEach(label => {
      const dimensions = this.getTextDimension(doc, label.title, DownloadEvidencesFontSizes.sectionContentTitle);
      maxDimensions.xPosition = dimensions.xPosition > maxDimensions.xPosition ? dimensions.xPosition : maxDimensions.xPosition;
      maxDimensions.yPosition = dimensions.yPosition > maxDimensions.yPosition ? dimensions.yPosition : maxDimensions.yPosition;
    });

    maxDimensions.xPosition = maxDimensions.xPosition + PADDING;

    data.content.forEach(label => {
      const titleWidth = maxDimensions.xPosition;
      this.addLabel(doc, label.title, DownloadEvidencesFontSizes.sectionContentTitle, position);
      position.xPosition = position.xPosition + titleWidth;
      const content = label.content ? label.content : noInfo;
      if (label.wrapText) {
        const maxLabelWidth = data.width;
        const maxLabelWidthFirstLine = maxLabelWidth - maxDimensions.xPosition;
        let splitLabel: Array<string> = doc.splitTextToSize(content, maxLabelWidthFirstLine);
        this.addLabel(doc, splitLabel[0], DownloadEvidencesFontSizes.sectionContentData, position);
        position.xPosition = data.position.xPosition;
        position.yPosition = position.yPosition + maxDimensions.yPosition;
        splitLabel.splice(0, 1);
        const contentWraped = splitLabel.join();
        splitLabel = doc.splitTextToSize(contentWraped, maxLabelWidth);
        splitLabel.forEach(split => {
          this.addLabel(doc, split, DownloadEvidencesFontSizes.sectionContentData, position);
          position.xPosition = data.position.xPosition;
          position.yPosition = position.yPosition + maxDimensions.yPosition;
        });
      } else {
        this.addLabel(doc, content, DownloadEvidencesFontSizes.sectionContentData, position);
        position.xPosition = data.position.xPosition;
        position.yPosition = position.yPosition + maxDimensions.yPosition;
      }
    });

    return position;
  }

  /**
   * @description Calculate text dimensions in document
   * @param {jsPDF} doc pdf document
   * @param {string} text text to get Dimensions
   * @param {DownloadEvidencesFontSizes} fontSize FontSize of text
   */
  private getTextDimension(doc: jsPDF, text: string, fontSize: DownloadEvidencesFontSizes): Point {
    let fontStyle: string;

    fontStyle = fontSize === DownloadEvidencesFontSizes.sectionContentData ? BOLD_FONT_STYLE : NORMAL_FONT_STYLE;
    doc.setFont(HELVETIC_FONT_FAMILY, fontStyle);
    doc.setFontSize(fontSize);
    const textDimension = doc.getTextDimensions(text);
    const dimensions: Point = { xPosition: Math.ceil(textDimension.w), yPosition: Math.ceil(textDimension.h)};

    return dimensions;
  }

  /**
   * @description Add label to PDF document
   * @param {jsPDF} doc pdf document
   * @param {string} text text to add
   * @param {DownloadEvidencesFontSizes} fontSize FontSize of text
   * @param {Point} coords coords of label
   */
  private addLabel (doc: jsPDF, text: string, fontSize: DownloadEvidencesFontSizes, coords: Point): void {
    const fontColorHex = '#00263A';
    const fontWhiteColorHex = '#FFFFFF';

    let fontColor: string;
    let fontStyle: string;

    fontColor = fontSize === DownloadEvidencesFontSizes.status ? fontWhiteColorHex : fontColorHex;
    fontStyle = fontSize === DownloadEvidencesFontSizes.sectionContentData ? BOLD_FONT_STYLE : NORMAL_FONT_STYLE;

    doc.setTextColor(fontColor);

    doc.setFont(HELVETIC_FONT_FAMILY, fontStyle);
    doc.setFontSize(fontSize);
    doc.text(text, coords.xPosition, coords.yPosition);
  }

  /**
   * @description Downloads evidences of order in PDF format
   * @param {Array<EvidenceToDisplay} images Image evidences
   * @param {OrderData} orderData Order data
   * @param {ShipmentRequest} shipment Shipment of order
   * @param {string} fileName Name of file
   */
  public async downloadPDFEvidences(images: Array<EvidenceToDisplay>,
    orderData: OrderData,
    shipment: ShipmentRequest,
    fileName: string): Promise<void> {
    const biisLogoHeigth = 13;
    const biisLogoWidth = 20;
    const biisLogoPath = 'assets/biis-gray-icon.png';
    const biisLogoFormat = 'JPEG';
    const fillStyle = 'F';
    let firstPage = true;
    const fillColorHex = '#141914';
    const marginH = 10;
    const marginV = 10;
    const paddingStatus = 2;
    const pdfFromImage: PdfFromImage = {
      name: '',
      blob: null
    };
    const percentCol1 = 27;
    const percentCol2 = 29;
    const percentCol3 = 44;
    const radiusStatusRect = 3;

    const doc: jsPDF = new jsPDF();
    doc.setFillColor(fillColorHex);

    const labels: PDFEvidenceLabels = _clone(PDFLABELS_CONSTANTS);
    const pageWidth: number = doc.internal.pageSize.getWidth();
    const pageHeight: number = doc.internal.pageSize.getHeight();
    const printAreaWidth = pageWidth - (marginH + marginH);
    const col1Width = ((printAreaWidth / 100) * percentCol1) - PADDING;
    const col2Width = ((printAreaWidth / 100) * percentCol2) - PADDING;
    const col3Width = ((printAreaWidth / 100) * percentCol3) - PADDING;
    const lineLength = printAreaWidth - biisLogoWidth;
    const blobLogo = await this.http.get(biisLogoPath, {responseType: BLOB}).toPromise();
    const logoBiis = await this.convertoTob64(blobLogo);

    for (const image of images) {
      if (firstPage) {
        firstPage = false;
      } else {
        doc.addPage();
      }
      let evidenceData;
      if (image.id) {
        evidenceData = this.getEvidenceData(shipment, image);
      }
      let position: Point = { xPosition: marginH, yPosition: marginV };
      const headerSize = this.getTextDimension(doc, labels.evidences, DownloadEvidencesFontSizes.title);
      position.yPosition = position.yPosition + headerSize.yPosition;
      this.addLabel(doc, labels.evidences, DownloadEvidencesFontSizes.title, position);
      position.xPosition = position.xPosition + PADDING + headerSize.xPosition;

      const status = `${labels.shipmentStatus} ${orderData.status}`;
      const statusSize = this.getTextDimension(doc, status, DownloadEvidencesFontSizes.status);
      statusSize.xPosition = statusSize.xPosition + paddingStatus + paddingStatus;
      statusSize.yPosition = statusSize.yPosition + paddingStatus + paddingStatus;
      const rectPosition: Point = { xPosition: position.xPosition, yPosition: position.yPosition - statusSize.yPosition };
      doc.roundedRect(rectPosition.xPosition,
        rectPosition.yPosition,
        statusSize.xPosition,
        statusSize.yPosition,
        radiusStatusRect,
        radiusStatusRect,
        fillStyle);
      position.yPosition = position.yPosition - paddingStatus;
      position.xPosition = position.xPosition + paddingStatus;
      this.addLabel(doc, status, DownloadEvidencesFontSizes.status, position);

      const orderIdLabel = `${orderData.identifier}`;
      const shipmentSize = this.getTextDimension(doc, orderIdLabel, DownloadEvidencesFontSizes.shipment);
      const shipmentPosition: Point = {
        xPosition: (marginH + lineLength) - shipmentSize.xPosition,
        yPosition: position.yPosition + paddingStatus
      };
      this.addLabel(doc, orderIdLabel, DownloadEvidencesFontSizes.shipment, shipmentPosition);
      const logoCoords: Point = {
        xPosition: marginH + lineLength,
        yPosition: position.yPosition - biisLogoHeigth + THREE
      };
      doc.addImage(logoBiis, biisLogoFormat, logoCoords.xPosition, logoCoords.yPosition, biisLogoWidth, biisLogoHeigth);
      position.yPosition = position.yPosition + LINE_BREAK;
      position = { xPosition: marginH, yPosition: marginV };
      position.yPosition = position.yPosition + headerSize.yPosition + ONE;
      doc.rect(position.xPosition, position.yPosition, lineLength, ONE, fillStyle);
      position.yPosition = position.yPosition + ONE;
      const sectionTitlePosition: Point = _clone(position);

      const titleDimensions = this.getTextDimension(doc, labels.account, DownloadEvidencesFontSizes.sectionTitle);
      sectionTitlePosition.yPosition = sectionTitlePosition.yPosition + titleDimensions.yPosition;

      let sectionContent: Array<PDFEvidenceContent> = [
        { title: labels.shipment, content: shipment.referenciaInterna },
        { title: labels.deliveryDate, content: this.convertDateToString(orderData.deliveryDate) },
        { title: labels.invoice, content: orderData.invoice },
        { title: labels.order, content: orderData.folio }
      ];
      let section: PDFEvidenceSection = {
        content: sectionContent,
        position: sectionTitlePosition,
        width: col1Width
      };
      let maxSectionDataSize: number;
      let sectionSize = this.addSectionData(doc, section);
      maxSectionDataSize = sectionSize.yPosition;

      sectionTitlePosition.xPosition = sectionTitlePosition.xPosition + col1Width + PADDING;

      const type =  !evidenceData ? image['type'] : evidenceData.evidenceType;
      sectionContent = [
        { title: labels.orderData, content: orderData.orderGrouper },
        { title: labels.orderInternalReference, content: orderData.internalReference },
        { title: labels.shipmentId, content: shipment.id },
        { title: labels.evidenceType, content: type}
      ];
      section = {
        content: sectionContent,
        position: sectionTitlePosition,
        width: col2Width
      };
      sectionSize = this.addSectionData(doc, section);
      maxSectionDataSize = sectionSize.yPosition > maxSectionDataSize ? sectionSize.yPosition : maxSectionDataSize;

      sectionTitlePosition.xPosition = sectionTitlePosition.xPosition + col2Width + PADDING;

      const folio = !evidenceData ? image['folio'] : evidenceData.folio;
      sectionContent = [
        { title: labels.account, content: orderData.account.name, wrapText: true },
        { title: labels.stop, content: orderData.destination.name, wrapText: true },
        { title: labels.description, content: folio, wrapText: true }
      ];
      section = {
        content: sectionContent,
        position: sectionTitlePosition,
        width: col3Width
      };
      sectionSize = this.addSectionData(doc, section);
      maxSectionDataSize = sectionSize.yPosition > maxSectionDataSize ? sectionSize.yPosition : maxSectionDataSize;

      position.xPosition = marginH;
      position.yPosition = maxSectionDataSize + LINE_BREAK;

      let imageHeight = pageHeight - marginV;
      imageHeight = imageHeight - position.yPosition;
      let extension = this.getExtensionFromName(image.name);
      const MIMEType = this.getMIMEtype(extension);
      if (extension === JPG) {
        extension = JPEG;
      }
      const blob = new Blob([image.file], { type: MIMEType });
      const b64 = await this.convertoTob64(blob);
      doc.addImage(b64, extension.toUpperCase(), position.xPosition, position.yPosition, printAreaWidth, imageHeight);
    }
    const resultblob = new Blob([doc.output(BLOB)], { type: PDFFILETYPE });
    pdfFromImage.name = fileName;
    pdfFromImage.blob = resultblob;
    FileSaver.saveAs(pdfFromImage.blob, `${pdfFromImage.name}${DOT}${PDF}`);
  }


  /**
   * @description Download a file
   * @param {File} file Image to download
   * @param {string} fileName Name of file to download
   */
  public downloadFile(file: File, fileName: string): void {
    const blob = new Blob([file], { type: file.type});
    FileSaver.saveAs(blob, fileName);
  }

  /**
   * @description Get evidence data of shipment by image
   * @param {ShipmentRequest} shipment Shipmnet of evidence
   * @param {EvidenceToDisplay} image Image of evidence
   * @returns {PictureData} Evidence data of shipmnet
   */
  private getEvidenceData(shipment: ShipmentRequest, image: EvidenceToDisplay): PictureData {
    const id = image.id;
    let evidence: PictureData;
    shipment.detalles.forEach((detail) => {
      const data = detail.evidencias.foto.find(element => element.img['_id'] === id);
      if (data) {
        evidence = data;
      }
    });

    return evidence;
  }

  /**
   * @description Convert Date to string in format 'dd/mm/yyyy'
   * @param {Date} deliveryDate Date to be convert
   * @returns {string} Date in format 'dd/mm/yyyy'
   */
  private convertDateToString(deliveryDate: Date): string {
    const dateToSplit = new Date(deliveryDate);
    const dd = String(dateToSplit.getDate()).padStart(2, '0');
    const mm = String(dateToSplit.getMonth() + 1).padStart(2, '0');
    const yyyy = dateToSplit.getFullYear();
    const date = dd + '/' + mm + '/' + yyyy;
    return date;
  }

  /**
   * @description Method that automatic downloads a PDF file with evidences/POD of an order
   * @param pdfInfo Array of strings: data to print in PDF file
   * @param images Array of EvidenceToDisplay: Array that contains the evidences
   */
  public async downloadImagesInPDF(images: Array<EvidenceToDisplay>, pdfInfo: Array<string>, fileName: string): Promise<void> {
    const doc = new jsPDF();
    const pdfFromImage: PdfFromImage = {
      name: '',
      blob: null
    };
    const newPages = images.length - ONE;
    for (let i = 0; i < newPages; i++) {
      doc.addPage();
    }
    for (let i = 0; i < images.length; i++) {
      doc.setPage(i + ONE);
      let heightPositionText = LINE_BREAK;
      for (let j = 0; j < pdfInfo[i].length; j++) {
        doc.setFontSize(FONT_SIZE);
        const textSplit = doc.splitTextToSize(pdfInfo[i][j], MAX_TEXT_WIDTH);
        for (const text of textSplit) {
          doc.fromHTML(text, WIDTH_POSITION, heightPositionText);
          heightPositionText += LINE_BREAK;
        }
      }
      heightPositionText += LINE_BREAK;
      let extension = this.getExtensionFromName(images[i].name);
      const MIMEType = this.getMIMEtype(extension);
      if (extension === JPG) {
        extension = JPEG;
      }
      const blob = new Blob([images[i].file], { type: MIMEType });
      const b64 = await this.convertoTob64(blob);
      doc.addImage(b64, extension.toUpperCase(), WIDTH_POSITION, heightPositionText, WIDTH_IMAGE, HEIGHT_IMAGE);
    }
    const resultBlob = new Blob([doc.output(BLOB)], { type: PDFFILETYPE });
    pdfFromImage.name = fileName;
    pdfFromImage.blob = resultBlob;
    FileSaver.saveAs(pdfFromImage.blob, pdfFromImage.name + DOT + PDF);
  }

  /**
   * @description Gets extesion from file name
   * @param {string} name File name
   * @returns {string} extension
   */
  private getExtensionFromName(name: string): string {
    let result = '';
    const extension = name.split('.');
    result = extension[extension.length - 1];

    return result;
  }

  /**
   * @description Gets MIME type for a file
   * @param {string} MIMEType Extension current file
   * @returns {string} A MIME type assigned
   */
  private getMIMEtype(MIMEType: string): string {
    let result = '';
    switch (MIMEType) {
      case 'jpg':
        result = 'image/jpeg';
        break;
      case 'jpeg':
        result = 'image/jpeg';
        break;
      case 'pdf':
        result = 'application/pdf';
        break;
      case 'png':
        result = 'image/png';
        break;
      default:
        result = 'application/octet-binary';
    }

    return result;
  }

  /**
   * @description Create and download a text file
   * @param {string} data The file data
   * @param {string} filename The file name
   */
  public saveTextAsFile(data: string, filename: string): void {
    const blob = new Blob([data], { type: TEXT_FILE_FORMAT });
    const event = document.createEvent(MOUSE_EVENT);
    const aTag = document.createElement(A_TAG);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      window.navigator.msSaveOrOpenBlob(blob, filename);
    } else {
      aTag.download = filename;
      aTag.href = window.URL.createObjectURL(blob);
      aTag.dataset.downloadurl = [TEXT_FILE_FORMAT, aTag.download, aTag.href].join(COLON_SYMBOL);
      event.initEvent(MOUSE_CLICK_EVENT, true, false);
      aTag.dispatchEvent(event);
    }
  }

  /**
   * @description Build the file's data foreach error
   * @param {any} error Catched error
   * @returns {string} Data file
   */
  public buildDataErrorFile(error: any): string {
    let dataError: string;
    dataError = '';
    if (error.error && error.error.invalid) {
      const invalid = error.error.invalid;
      const breakLine = '\n';
      const rowSentence = '. fila: ';
      let data = '';

      for (let i = 0; i < invalid.length; i++) {
        if (invalid[i].invalid && Array.isArray(invalid[i].invalid)) {
          data += this.buildSubContent(invalid[i].invalid);
        } else if (invalid[i].error && invalid[i].error.error && invalid[i].error.error.error) {
          data += this.buildSubContent([invalid[i].error.error]);
        } else {
          const row = (invalid[i].row) ? rowSentence + invalid[i].row : '';
          data += invalid[i].message + row + breakLine;
        }
      }
      dataError = data;
    } else if (error.error && error.error.message) {
      dataError = error.error.message;
    } else {
      dataError = (error.message) ? error.message : '';
    }
    return dataError;
  }


  /**
   * @description Create the error log file
   * @param {Array<any>} errorr Catched errors
   */
  public createErrorFile(errors: Array<any>, fileName: string): void {
    let data: string;
    data = '';
    for (const error of errors) {
      data += this.buildDataErrorFile(error);
    }
    this.saveTextAsFile(data, fileName);
  }

  /**
   * @description Build subcontent error
   * @param {any[]} content Errors content
   * @param {number} index Error position
   * @return [string] Parsed subcontent
   */
  private buildSubContent(content: any[]): string {
    let data = '';
    const breakLine = '\n';
    const rowSentence = '. fila: ';
    const emptySpace = ' ';

    for (let i = 0; i < content.length; i++) {
      if (content[i].error && !content[i].row) {
        data += emptySpace + content[i].error + breakLine;
      } else {
        const row = (content[i].row) ? rowSentence + content[i].row : emptySpace;
        data += emptySpace + content[i].message + row + breakLine;
      }
    }

    return data;
  }

}
