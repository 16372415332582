import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import { MatchedGuidesResponse, ShipmentRequest } from '../../interfaces';
import { ShipmentOrderReceptionStatus } from '../../interfaces/order-reception';

const apiToken = environment.apiToken;
const coreApiUrl = environment.apiUrl;
const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': apiToken});
const shipmentApiUrl = environment.shipmentApiUrl;

@Injectable()
export class ShipmentProvider {

  constructor(
    private http: HttpClient
    ) { }
  /**
   * @desctiption Gets shipment by shipmentID
   * @param {string} tenantId Current tenant Oid
   * @param {string} shipperId Current shipper Oid
   * @param {string} shipmentToSearch Shipper identifier
   * @returns {Object} Current shipment object
   */
  public async getShipmentByShipmentId(tenantId: string, shipperId: string, shipmentToSearch: string): Promise<Array<ShipmentOrderReceptionStatus>> {
    return await this.http.get<Array<ShipmentOrderReceptionStatus>>(shipmentApiUrl + '/tenantId/' + tenantId + '/shipperId/' + shipperId
      + '/shipment/' + shipmentToSearch, {'headers': headers}).toPromise();
  }

  /**
   * @description Gets shipment request by Oid
   * @param {string} shipmentRequestOid Shipment request Oid
   * @returns {ShipmentRequest} Shipment request found
   */
  public async getShipmentRequestByOid(shipmentRequestOid: string): Promise<ShipmentRequest> {
    return await this.http.get<ShipmentRequest>(coreApiUrl + '/solicitudes/' + shipmentRequestOid, {'headers': headers}).toPromise();
  }

  /**
   * @description Get matches between white guides and courier guides
   * @param {string} tenantId The current shipperOId to find
   * @param {string} folio The order's folio to find
   * @returns {Promise<RevertableShipmentResponse>} Object matches guides
   */
   public async getMatchedGuidesByOrder(tenantId: string, folio: string): Promise<Array<MatchedGuidesResponse>> {
    return await this.http.get<Array<MatchedGuidesResponse>>
    (`${shipmentApiUrl}/tenant/${tenantId}/order/${folio}/matchedGuides`, {'headers': headers}).toPromise();
  }

}