<mat-expansion-panel [expanded]=true>

  <mat-expansion-panel-header collapsedHeight='2.5em' expandedHeight='2.5em' *ngIf="isHeader">
      <mat-panel-title class="show-label">
        {{ labels.labels }}
      </mat-panel-title>
    </mat-expansion-panel-header>

    <mat-tab-group mat-stretch-tabs class="example-stretched-tabs mat-elevation-z4">
      <mat-tab label="{{labels.whiteLabels}}">
        <table id="whiteLablesTable" [width]="maxWidth" *ngIf="whiteLabelsTable && whiteLabelsTable.length > 0">
          <tr *ngFor="let row of whiteLabelsTable" [width]="maxWidth">
            <td [width]="whiteTdwidth" *ngFor="let column of row.columns"><div>{{ column }}</div></td>
          </tr>
        </table>
        <div *ngIf="whiteLabelsTable && whiteLabelsTable.length === 0" class="noInfo">
          <br>
          {{ labels.noInfo}}
        </div>
      </mat-tab>
      <mat-tab label="{{labels.courierLabels}}">
        <table id="courierLablesTable" [width]="maxWidth" *ngIf="courierLabelsTable && courierLabelsTable.length > 0">
          <tr *ngFor="let row of courierLabelsTable" [width]="maxWidth">
            <td *ngFor="let column of row.columns" [width]="courierTdwidth"><div>{{ column }}</div></td>
          </tr>
        </table>
        <div *ngIf="courierLabelsTable && courierLabelsTable.length === 0" class="noInfo">
          <br>
          {{ labels.noInfo}}
        </div>
      </mat-tab>
      <mat-tab label="{{labels.matchedGuides}}">
        <table id="courierLablesTable" [width]="maxWidth" *ngIf="matchedLabelsTable && matchedLabelsTable.length > 0">
          <tr [width]="maxWidth" *ngFor="let row of matchedLabelsTable">
            <td [width]="matchedTdwidth" *ngFor="let column of row.columns"><div>{{ column }}</div></td>
          </tr>
        </table>
        <div *ngIf="matchedLabelsTable && matchedLabelsTable.length === 0" class="noInfo">
          <br>
          {{ labels.noInfo}}
        </div>
      </mat-tab>
    </mat-tab-group>
</mat-expansion-panel>
