import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import {
  EvidenceApiResponse,
  FilePathParams,
  OptionalParamsEvidenceSearch,
  ParamsEvidenceToSave,
  PathBuilderResponse
} from '../../interfaces';

const apiToken = environment.apiToken;
const BASEURLV3 = environment.evidencesStorageUrl + environment.evidencesContainer;
const EVIDENCE_API_URL = environment.tmsEvidenceApiUrl;
const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8', 'Ocp-Apim-Subscription-Key': apiToken});

@Injectable()

/**
 * Service to add all endpoints for evidence-api.
 */
export class EvidencesProviderService {
  /**
   * @description Evidence provider service.
   * @param { HttpClient } http - Service instance for http request.
   */
  constructor(
    private http: HttpClient
  ) { }

  /**
   * @description Gets evidence by order object identifier.
   * @param { string } shipperOid - Current shipper unique identifier.
   * @param { string } orderOid - Order object identifier.
   * @param { OptionalParamsEvidenceSearch } optionalParams - Optional params to search an evidence.
   * @returns { EvidenceApiResponse } - Evidence found successfully.
   */
  public async getEvidenceByOrderOid(
    shipperOid: string,
    orderOid: string,
    optionalParams?: OptionalParamsEvidenceSearch
  ): Promise<EvidenceApiResponse> {
    let url = `${EVIDENCE_API_URL}/tenant/${shipperOid}/order/${orderOid}`;

    if (optionalParams) {
      for (const param in optionalParams) {
        url = url + `?${param}=${optionalParams[param]}`;
      }
    }

    return await this.http.get<EvidenceApiResponse>(url, { headers: headers }).toPromise();
  }

  /**
   * @description Gets path files from the files passed.
   * @param { string } tenantOid - Current tenant/shipper identifier.
   * @param { Array<FilePathParams> } filesParams - Files params to get file path.
   * @returns { Array<PathBuilderResponse> } Path files built and success message.
   */
  public async getPathBuilderFiles(tenantOid: string, filesParams: Array<FilePathParams>): Promise<PathBuilderResponse> {
    const url = `${EVIDENCE_API_URL}/pathBuilder/tenant/${tenantOid}`;

    return await this.http.post<PathBuilderResponse>(url, filesParams).toPromise();
  }

  /**
   * @description Saves evidence in the order selected.
   * @param { string } shipperOid - Current shipper unique identifier.
   * @param { ParamsEvidenceToSave } params - Params to save in the evidence.
   * @returns { EvidenceApiResponse } - Evidence saved successfully.
   */
  public async saveEvidenceOrder(shipperOid: string, params: ParamsEvidenceToSave): Promise<EvidenceApiResponse> {
    const shipmentId = params.shipmentId;
    const orderOid = params.orderOid;
    const evidenceData = params.evidenceBody;
    const url = `${EVIDENCE_API_URL}/tenant/${shipperOid}/shipment/${shipmentId}/orderId/${orderOid}`;

    return await this.http.post<EvidenceApiResponse>(url, evidenceData).toPromise();
  }

  /**
   * @description Build Evidence URL.
   * @param {string} path - File path in Blob storage.
   * @param {string} fileName - Evidence File name.
   * @returns {string} Evidence File URl.
   */
  public buildEvidenceFileURL(path: string, fileName: string): string {
    return `${BASEURLV3}${path}${fileName}`;
  }
}
