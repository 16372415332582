export const PDFLABELS_CONSTANTS = {
  account: 'Cuenta',
  deliveryDate: 'Entrega',
  description: 'Desc.',
  docType: 'Tipo de documento',
  evidence: 'Evidencia',
  evidences: 'Evidencias',
  evidenceType: 'Tipo evid.',
  invoice: 'Factura',
  noInfo: 'Sin información',
  order: 'Orden',
  orderData: 'Pedido',
  orderId: 'ID Orden',
  orderInternalReference: 'Ref. Int.',
  orderNumber: 'No. Orden',
  shipment: 'Embarque',
  shipmentId: 'Solicitud',
  shipmentStatus: 'ESTATUS:',
  stop: 'Parada'
};