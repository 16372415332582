<scf-header
  [solution]="solution"
  [showUserPanel]="false"
  [hideHeaderElements]="true">
</scf-header>
<scf-sidebar
  [solution]="solution"
  [items]="" [ngClass]="{'logout-request': false}"
  [hideSidebar]="true">
  <router-outlet scf-workspace>
  </router-outlet>
</scf-sidebar>

