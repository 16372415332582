export const MiniCarouselConstants = {
  CONFIRM: 'Confirm',
  DOT_CHAR: '.',
  EMPTY_STRING: '',
  GOOGLE_SUCCESS_RESPONSE: 'OK',
  IVA_FACTOR: 0.16,
  OBJECT_ID_KEY: '_id',
  PDF_PREFIX: 'pdf',
  RETENTION_FACTOR: 0.04,
  SYSTEM_DEFAULT_LOGO: 'assets/icons/solutions/tep-logo.svg',
  USER_INFO_KEY: 'userInfo'
};