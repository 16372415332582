export const OrderHistoricClientConstants = {
  CAROUSELTITLE: '',
  HISTORY_COLUMNS: [
    'date',
    'time',
    'movement',
    'description',
    'user'
  ],
  KEY_EVIDENCE_ORIGIN: 'evidenceOrigin',
  KEY_ORDER: 'order',
  KEY_SHIPMENT: 'shipment',
  ORIGIN_EVIDENCE_CONTROL: 'Control de Evidencias',
  ORIGIN_MOBILITY: 'Mobility',
  PRODUCT_COLUMNS: [
  'name',
  'code',
  'unit',
  'total',
  'accepted',
  'rejected',
  'reason',
  'price'
  ],
  REQUEST_START: 'S20',
  SHIPMENT_COLUMNS: [
  'shipment',
  'mobilityId',
  'internalReference',
  'carrier',
  'driver',
  'plates',
  'originWarehouse',
  'inRouteDate',
  'status'
  ],
};
