export enum ProofOfDeliveryLabels {
  appointmentDateParagraph = 'Fecha inicio ruta',
  boxesParagraph = 'Total de Cajas',
  carrierParagraph = 'Línea de Transporte',
  dateParagraph = 'Fecha: ',
  deliveryDateParagraph = 'Fecha de entrega real',
  deliveryDateEstimated = 'Fecha entrega estimada',
  deliveryInformationSubtitle = 'Información de la entrega',
  deliveryToParagraph = 'Entregado a',
  destinationParagraph = 'Destino',
  driverParagraph = 'Chofer',
  errorGettingShipperLogo = 'Error al obtener información del embarcador',
  generalInformationSubtitle = 'Información general del viaje',
  messageFooter = 'Gracias por su preferencia, el equipo de BIIS está a sus ordenes.',
  noName = 'Sin Nombre',
  noSignature = 'Sin Firma',
  originParagraph = 'Origen',
  palletsParagraph = 'Total de Tarimas',
  piecesParagraph = 'Total de Piezas',
  presentationParagraph = 'Estimado cliente:',
  shipmentParagraph = 'A continuación te mostramos la prueba de entrega de la solicitud ',
  signatureParagraph = 'Firma',
  statusDeliveryParagraph = 'Estado de la entrega',
  title = 'Prueba de entrega',
  tripStateParagraph = 'Estado del Viaje',
  tripTypeParagraph = 'Tipo de Viaje',
  vehiclePlatesParagraph = 'Placas camión',
  vehicleTypeParagraph = 'Tipo de Vehículo',
  volumeParagraph = 'Volumen Total',
  weightParagraph = 'Peso Total (Kg)'
 }

