
<div class="background" [hidden]="showHistoricOrder">
  <div class="general-container">
    <div class ="cards-container">
      <div fxLayout="row">
        <mat-card fxFlex="60%" class="mat-card-search-order">
          <mat-card-content class="search-order-client-data">
            <mat-grid-list cols="2" rowHeight="1:.3">
              <form [formGroup]="searchOrderDataForm">
                <mat-grid-tile>
                  <div fxLayout="row" class="layout-search-order">
                    <div fxFlex="15%" class="flex-icon">
                      <mat-icon class="icon"></mat-icon>
                    </div>
                    <div fxFlex="80%" class="flex-icon">
                      <h2>{{ labels.searchOrder }}</h2>
                    </div>
                  </div>
                </mat-grid-tile>
                <mat-grid-tile>
                </mat-grid-tile>
                <mat-grid-tile>
                  <mat-form-field>
                    <input
                      id="inputOrderId"
                      required
                      matInput
                      formControlName="orderIdentifier"
                      placeholder="{{ labels.orderFolio }}">
                      <mat-error *ngIf="searchOrderDataForm.controls['orderIdentifier'].hasError('required')">
                        {{ labels.searchOrderIdentifier }}<strong>{{ labels.required }}</strong>
                      </mat-error>
                      <mat-error *ngIf="searchOrderDataForm.controls['orderIdentifier'].hasError('maxlength')">
                        {{ labels.maxLenthOrderId }}
                      </mat-error>
                  </mat-form-field>
                </mat-grid-tile>
                <mat-grid-tile>
                  <button
                    id="searchOrderButton"
                    mat-raised-button
                    color="primary"
                    (click)="searchOrder()"
                    [disabled]="searchOrderDataForm.invalid || searchingOrder"
                    class="primary-button align-right">
                    {{ labels.search }}
                  </button>
                </mat-grid-tile>
              </form>
            </mat-grid-list>
          </mat-card-content>
        </mat-card>
      </div>
    </div>
  </div>
  <div>
    <img
      class="powered"
      alt="PoweredBy"
      src="../../../../assets/icons/powered.svg">
  </div>
</div>

<app-order-historic-client
  *ngIf="showHistoricOrder">
</app-order-historic-client>