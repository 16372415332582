import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { AppConstants } from '../../constants/app-constants.constants';
import { MiniCarouselConstants } from './mini-carousel.constants';
import { DownloadFilesService } from '../../services/utils/download-file.service';
import {
  ArrayOids,
  FileData,
  EvidenceApiResponse,
  EvidenceHistoryData,
  EvidenceHistoryInfoData,
  EvidenceToDisplay,
  File,
  FilesToShow,
  GenericEvidenceLabels,
  GenericIncidenceLabels,
  IncidenceData,
  ItemShipmentEvidences,
  MiniCarouselLabel,
  OrderData,
  OrdersApi,
  PictureData,
  ShipmentRequest } from '../../interfaces';
import { BlobProviderService } from '../../providers/evidence/blob-provider.service';
import { DialogFilePreviewComponent } from '../dialog/dialog-file-preview/dialog-file-preview.component';
import { environment } from '../../../environments/environment';
import { EvidenceProvider } from '../../providers/evidence/evidence-provider.service';
import { FileConversorService } from '../../services/utils/file-conversor.service';
import { MiniCarouselTags } from './mini-carousel.labels';
import { OrderProvider } from '../../providers/orders/order-provider.service';
import { ShipmentProvider } from './../../providers/shipment/shipment-provider.service';
import { ToastrAlertsService } from '../../services/utils/toastr-alerts.service';

import * as _ from 'lodash';

const BASEURI = environment.baseStorageUrl + environment.evidenceContainer;
const DIALOG_WIDTH = '650px';
const EMPTY_STRING = '' ;
const KEY_DISPLAY_INFO = 'displayInfo';
const KEY_EVIDENCE_INFO = 'evidenceInfo';
const KEY_EVIDENCE_INFO_V3 = 'evidenceInfoV3';
const KEY_INCIDENCE_INFO = 'incidenceInfo';
const PDF_EXTENSION = '.pdf';
const SLASH = '/';
const URL = environment.baseStorageUrl + environment.mainContainer;
const URL_NOT_IMAGE = '../../../assets/notImage.png';

@Component({
  selector: 'app-mini-carousel',
  templateUrl: './mini-carousel.component.html',
  styleUrls: ['./mini-carousel.component.scss'],
  providers: [MatDialog]
})
export class MiniCarouselComponent implements OnInit, OnChanges {
  @Input() public displayInfo: boolean;
  @Input() public downloadService: DownloadFilesService;
  @Input() public evidences: EvidenceApiResponse;
  @Input() public evidenceAPIInfo: Array<EvidenceHistoryData>;
  @Input() public evidencesVersion: string;
  @Input() public genericTags: GenericEvidenceLabels | GenericIncidenceLabels;
  @Input() public readonly images: Array<string>;
  @Input() public incidencesData: Array<IncidenceData>;
  @Input() public isIncidence: boolean;
  @Input() public mobilityInfo: Array<EvidenceHistoryInfoData>;
  @Input() public mobilityURLs: Array<string>;
  @Input() public orderFolio: string;
  @Input() public orderOid: string;
  @Input() public title: string;
  @Input() public shipperOid: string;

  public actualPosition: number;
  public auxEvidenceFile: Array<object>;
  public disableOneFileButton: boolean;
  public downloadAvailable: boolean;
  public evidenceImages: Array<EvidenceToDisplay>;
  public evidencesFileAPI: Array<EvidenceToDisplay>;
  public evidenceURI: Array<string>;
  public fileImages: Array<EvidenceToDisplay>;
  public labels: MiniCarouselLabel;
  public noInfo: boolean;
  public order: OrderData;
  public pdfEvidences: Array<FilesToShow>;
  public shipment: ShipmentRequest;

  constructor(
    private blobProvider: BlobProviderService,
    public dialog: MatDialog,
    private evidencesProvider: EvidenceProvider,
    private fileConversorService: FileConversorService,
    private orderProvider: OrderProvider,
    private shipmentProvider: ShipmentProvider,
    private toast: ToastrAlertsService
  ) { this.labels = _.cloneDeep(MiniCarouselTags); }

  /**
   * @description Event fires when init component
   */
  public async ngOnInit(): Promise<void> {
    this.disableOneFileButton = false;
    this.actualPosition = 0;
    this.fileImages = [];
    if (!this.images || !this.images.length) {
      this.noInfo = true;
    } else {
      this.noInfo = false;
      await this.getOrderAndShipment();
      await this.fileConversorService.fileConversor(this.images, false).then((res) => {
        this.fileImages = res;
      });
    }
    if (this.downloadService) {
      this.downloadAvailable = true;
    } else {
      this.downloadAvailable = false;
    }
    if (this.genericTags && this.isIncidence) {
      this.labels.dialogPreviewTitle = this.genericTags.detailTitle;
      this.labels.tooltipDownload = this.genericTags.tooltipDownload;
    } else {
      this.labels = MiniCarouselTags;
    }
    this.disableOneFileButton = this.disabledDownloadOneFile(this.images);
  }

  /**
   * @description Get Evidence info for each image v3.
   */
  public getEvidenceInfoForImages(): void {
    for (const file of this.fileImages) {
      for (const shipment of this.evidences.item.shipments) {
        shipment.items.find(item => {
          if (item.fileName === file.name) {
            file.folio = item.folio;
            file.version = AppConstants.EVIDENCE_MAIN_VERSION;

            return true;
          }
        });
      }
    }
  }

  ngOnChanges() {
    this.ngOnInit();
  }

  /**
   * @description Get Order and shipment data
   */
  public async getOrderAndShipment(): Promise<void> {
    const orderId: ArrayOids = { ordersIds: [ this.orderOid ] };
    const orders: Array<OrdersApi> = await this.orderProvider.getOrdersByOids(orderId, this.shipperOid);
    this.order = this.convertToOrderData(orders[0]);
    if (this.order) {
      const shipmentOid = orders[0].currentShipment._id;
      this.shipment = await this.shipmentProvider.getShipmentRequestByOid(shipmentOid);
      await this.getEvidencesOfShipment();
    }
  }

  /**
   * @description Get evidences of Shipment
   */
  public async getEvidencesOfShipment() {
    const files: Array<PictureData> = [];
    this.shipment.detalles.forEach(detail => {
      files.push(...detail.evidencias.foto);
    });
    await this.getEvidencesFiles(files);
  }

  /**
   * @description Get evidence Files
   * @param {Array<PictureData} files Array of PictureData with all pictures of shipment
   */
  public async getEvidencesFiles(files: Array<PictureData>): Promise<void> {
    try {
      const additionalData: Array<FileData> = [];
    const evidencesFile = [];
    const urlImages: Array<string> = [];
    await this.getEvidencesAPI();

    files.forEach(evidence => {
      evidencesFile.push({ _id: evidence.img['_id'] });
    });

    const ids = { evidencesFile };
    const evidenceImages = await this.evidencesProvider.getFilesByOids(ids);
    const images: Array<File> = [];

    evidencesFile.forEach(id => {
      const img = evidenceImages.find(evidence => evidence._id === id._id);
      if (img) { images.push(img); }
    });

    images.forEach(img => {
      urlImages.push(img.nombre !== null ? `${URL}${img.nombre}` : URL_NOT_IMAGE);
    });

    evidencesFile.forEach(id => {
      additionalData.push({ id: id._id });
    });

    this.pdfEvidences = [];
    await this.fileConversorService.fileConversor(urlImages, false, null, additionalData).then((res) => {
      this.pdfEvidences = res;
    });

    } catch (error) {
      this.toast.errorAlert(MiniCarouselTags.toastErrorOrder);
    }
  }

  /**
   * @description Gets Evidences file from Evidence API
   */
  public async getEvidencesAPI(): Promise<void> {
    try {
      const params = {
        shipmentFolio: this.shipment.id,
        tenantId: this.shipperOid
      };
      const evidenceByShipment = await this.evidencesProvider.getByShipment(params);
      const ordersIdentifierBody = {
        ordersIdentifiers: []
      };
      if (evidenceByShipment) {
        for (const evidence of evidenceByShipment.evidence.ordersEvidence) {
          ordersIdentifierBody.ordersIdentifiers.push(evidence.orderId);
        }
        this.auxEvidenceFile = [];
        const fullOrders = await this.orderProvider.getOrdersByIdentifiers(ordersIdentifierBody, this.shipperOid);
        const currentOrder = fullOrders.orders.find(order => order._id === this.orderOid);
        this.evidenceURI = [];
        for (const evidence of evidenceByShipment.evidence.ordersEvidence) {
          if (currentOrder && (evidence.orderId === currentOrder.identifier)) {
            for (const file of evidence.file) {
              this.evidenceURI.push(this.buildFileURI(file, evidence.orderId, this.shipment.id));
              const auxObj = {
                type: evidence.notes,
                folio: evidence.folio,
                file: evidence.file
              };
              this.auxEvidenceFile.push(auxObj);
            }
          }
        }
        this.evidencesFileAPI = await this.fileConversorService.fileConversor(this.evidenceURI, false);
      }
    } catch (error) {
      this.toast.errorAlert(MiniCarouselTags.errorGettingEvidences);
    }
  }

  /**
   * @description Builds File URI
   * @param {string} fileName Current file name
   * @param {string} orderId Order Identifier
   * @param {string} shipmentFolio Current shipment folio request (S2)
   * @returns {string} A file URI build
   */
  private buildFileURI(fileName: string, orderId: string, shipmentFolio: string): string {
    const fileURI = BASEURI + shipmentFolio + SLASH + orderId + SLASH + fileName;

    return fileURI;
  }

  /**
   * @description Show previous picture
   */
  public previousPicture(): void {
    if (this.actualPosition <= 0) {
      this.actualPosition = this.images.length - 1;
    } else {
      this.actualPosition--;
    }
  }

  /**
   * @description Show previous picture
   */
  public nextPicture(): void {
    if (this.actualPosition >= this.images.length - 1) {
      this.actualPosition = 0;
    } else {
      this.actualPosition++;
    }
  }

  /**
   * @description It validates if a URL string has pdf extension
   * @param file url file
   */
  public validatePDFExtension(file: string): boolean {
    file = file.toLowerCase();
    return file.endsWith(PDF_EXTENSION);
  }

  /**
   * @description It downloads the files in a zip using the downloadEvidences of the downloadService
   */
  public onDownloadEvidences(): void {
    this.toast.processingAlert();
    const orderName = this.cleanString(this.orderFolio);
    const allEvidences: Array<EvidenceToDisplay> = this.fileImages;
    this.downloadService.downloadEvidences(orderName, allEvidences, this.isIncidence);
    this.toast.closeProcessing();
    this.toast.successAlert(this.labels.toastSuccessDownload);
  }

  /**
   * @description Convert OrderApi to OrderData object
   * @param {OrdersApi} orderApi orderApi object
   * @returns {OrderData} OrderData object with ordersApi values
   */
  public convertToOrderData(orderApi: OrdersApi): OrderData {
    let order: OrderData;
    if (orderApi) {
      order = {
        _id: orderApi._id,
        account: {
          _id: orderApi.account._id,
          name: orderApi.account.name
        },
        additionalComments: undefined,
        appointmentHour: undefined,
        boxes: undefined,
        creationDate: undefined,
        deliveryDate: orderApi.deliveryDate,
        destination: {
          address: orderApi.destination.address,
          latitude: orderApi.destination.latitude,
          longitude: orderApi.destination.longitude,
          municipality: orderApi.destination.municipality,
          name: orderApi.destination.name,
          postalCode: orderApi.destination.postalCode,
          settlement: orderApi.destination.settlement,
          state: orderApi.destination.state
        },
        discount: undefined,
        evidences: orderApi.evidences,
        folio: orderApi.folio,
        identifier: orderApi.identifier,
        inRouting: undefined,
        internalReference: orderApi.internalReference,
        invoice: orderApi.invoice,
        orderGrouper: undefined,
        origin: undefined,
        pallets: undefined,
        pieces: undefined,
        products: undefined,
        shipperId: undefined,
        status: orderApi.status,
        tenantId: undefined,
        type: orderApi.type,
        volume: undefined,
        warehouseId: undefined,
        weight: undefined
      };
    }

    return order;
  }

  /**
   * @description Convert FilesToShow object to EvidenceToDisplay object
   * @param {FilesToShow} filesToShow FilesToShow to convert
   * @returns {EvidenceToDisplay} EvidenceToDisplay object
   */
  public convertFilesToShowToEvidenceToDisplay(filesToShow: FilesToShow): EvidenceToDisplay {
    const evidence: EvidenceToDisplay = {
      address: filesToShow.address,
      file: filesToShow.file,
      id: filesToShow.id,
      name: filesToShow.name,
      isMobilityEvidence: filesToShow.isMobilityEvidence,
      status: filesToShow.status,
      url: filesToShow.url
    };

    return evidence;
  }

  /**
   * @description Select the images to download
   * @param {Array<string>} fileIds images ids
   * @param {Array<EvidenceToDisplay>} evidences evidences to download
   */
  public getImages(fileIds: Array<string>, evidences: Array<EvidenceToDisplay>): void {
    this.evidenceImages = [];

    fileIds.forEach(id => {
      const img = evidences.find(evidence => evidence.id === id);
      if (img) {
        this.evidenceImages.push(img);
      }
    });
  }

  /**
   * @description Disable download incidence/evidence in one file button
   * @param {Array<string>} files An array with url files
   * @returns A true or false value
   */
  public disabledDownloadOneFile(files: Array<string>): boolean {
    if (files.length >= 1) {
      const extensions = [];
      for (const file of files) {
        extensions.push(file.split(MiniCarouselConstants.DOT_CHAR).pop());
      }
      const isBelowThreshold = (currentValue) => currentValue === MiniCarouselConstants.PDF_PREFIX;
      return extensions.every(isBelowThreshold);
    } else if (files.length === 0) {
     return true;
    }
  }

  /**
   * @description Event fires when Download evidences in PDF format
   */
  public async onDownloadPDFEvidences(): Promise<void> {
    try {
      const fileIds = this.order?.evidences ? this.order?.evidences?.map(element => element.file) : [];
      this.getImages(fileIds, this.pdfEvidences);
      if (this.auxEvidenceFile && this.auxEvidenceFile.length) {
        this.addingMissingDataEvidences();
        this.evidenceImages = [...this.evidenceImages, ...this.evidencesFileAPI];
      }

      if (this.evidences?.item?.shipments) {
        this.evidenceImages = this.evidences?.item?.shipments[0]?.items.length ? this.fileImages : this.evidenceImages;
        this.getEvidenceInfoForImages();
      }

      this.toast.processingAlert();
      const fileName = `${this.cleanString(this.orderFolio)}${PDF_EXTENSION}`;
      this.evidenceImages = this.evidenceImages.filter(e => e.url.split(MiniCarouselConstants.DOT_CHAR).pop() !== MiniCarouselConstants.PDF_PREFIX);
      await this.downloadService.downloadPDFEvidences(this.evidenceImages, this.order, this.shipment, fileName);
      this.toast.closeProcessing();
      this.toast.successAlert(this.labels.toastSuccessDownload);
    } catch (error) {
      this.toast.errorAlert(this.labels.toastErrorDownload);
    }
  }

  /**
   * @description Adding missing data for evidences objects
   */
  public addingMissingDataEvidences(): void {
    for (const auxEvidence of this.auxEvidenceFile) {
      for (let i = 0; i < this.evidencesFileAPI.length; i++) {
        if (this.evidencesFileAPI.find(e => e.name === auxEvidence['file'][i])) {
          this.evidencesFileAPI[i]['type'] = !auxEvidence['type'] ? EMPTY_STRING : auxEvidence['type'];
          this.evidencesFileAPI[i]['folio'] = auxEvidence['folio'];
        }
      }
    }
  }

  /**
   * @description When an image is clicked, it opens the file preview dialog
   * @param fileUrl URL string
   */
  public async onViewImage(fileUrl: string): Promise<void> {
    const fileInfo = this.fileImages.filter(i => i.url === fileUrl);
    const incidenceFound = this.incidencesData ?
    this.incidencesData.find((incidence: IncidenceData) => incidence.url === fileUrl) : undefined;
    const params = {
      data: {
        title: this.labels.dialogPreviewTitle,
        file: fileInfo[0].file
      },
      width: DIALOG_WIDTH
    };

    if (this.displayInfo && this.evidencesVersion !== AppConstants.EVIDENCE_MAIN_VERSION) {
      let evidenceExtraData = [];
      evidenceExtraData = this.evidenceAPIInfo.filter(evidence => {
        for (let k = 0; k < evidence.urls.length; k++) {
          if (evidence.urls[k] === fileUrl) {
            return evidence;
          }
        }
      });
      if (!evidenceExtraData.length) {
        evidenceExtraData = this.mobilityInfo.filter(mobility => {
          for (let k = 0; k < mobility.evidence.length; k++) {
            if (mobility.evidence[k].evidence.url === fileUrl) {
              return mobility;
            }
          }
        });
      }
      params.data[KEY_DISPLAY_INFO] = true;
      params.data[KEY_EVIDENCE_INFO] = evidenceExtraData[0];
      params.data[KEY_INCIDENCE_INFO] = { incidence: incidenceFound, isIncidence: this.isIncidence };
    }

    if (this.displayInfo && this.evidencesVersion === AppConstants.EVIDENCE_MAIN_VERSION) {
      params.data[KEY_DISPLAY_INFO] = true;
      params.data[KEY_EVIDENCE_INFO_V3] = this.completeEvidencesDataForV3(fileInfo[0].file);
    }

    this.dialog.open(DialogFilePreviewComponent, params);
  }

  /**
   * @description Complete Evidences data for v3 evidences.
   * @param {FilesToShow['file']} file - File Evidence.
   * @returns {ItemShipmentEvidences} All evidence data.
   */
  public completeEvidencesDataForV3(file: FilesToShow['file']): ItemShipmentEvidences {
    for (const shipment of this.evidences.item.shipments) {
      const itemFound = shipment.items.find(item => {
        if (item.fileName === file.name) {
          item['shipmentId'] = shipment.shipmentId;
          return true;
        }
      });

      if (itemFound) {
        return itemFound;
      }
    }
  }

  /**
   * @description Change character for '-'
   * @param name Current string
   * @returns String modified
   */
  private cleanString(name: string): string {
    return name.replace(/[|&;$%@"<>()+,/\.?#]/g, '-');
  }
}
