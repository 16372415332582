import { Injectable } from '@angular/core';

import { BlobProviderService } from '../../providers/evidence/blob-provider.service';
import { FileData, FilesToShow, POD } from '../../interfaces';

const KEY_ADDRESS = 'address';
const KEY_ID = 'id';
const KEY_ORDERID = 'orderId';
const STATUS = 'status';

@Injectable()
export class FileConversorService {

constructor(
  private blobProvider: BlobProviderService
) { }

  /**
   * @description Converts the url image as image
   * @param storageArray Url storage image
   * @param isMobilityEvidence Indicates if is a mobility evidence
   * @param pods POD existing
   * @param fileData Extra properties from files
   * @returns {Promise<FilesToShow>} The array with files data to show
   */
  public async fileConversor (storageArray: Array<string>, isMobilityEvidence: boolean,
    pods?: Array<POD>, fileData?: Array<FileData>): Promise<Array<FilesToShow>> {
    const filesToShow: Array<FilesToShow> = [];

    for (let index = 0; index < storageArray.length; index++) {
      const element = storageArray[index];
      const extensionSplit = element.split('.');
      const fileExtension = extensionSplit[extensionSplit.length - 1];
      const nameSplit = element.split('/');
      const fileName = nameSplit[nameSplit.length - 1];
      const extensionFile = await this.blobProvider.getMIMEtype(fileExtension);

      fetch(element)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], fileName, { type: extensionFile });
        const fileAux = {
          file: file,
          isMobilityEvidence: isMobilityEvidence,
          name: fileName,
          url: element
        };

        if (isMobilityEvidence && element === pods[index].url) {
          fileAux[STATUS] = pods[index].status;
        }

        if (fileData) {
          fileAux[KEY_ADDRESS] = fileData[index].address;
          fileAux[KEY_ID] = fileData[index].id;
          fileAux[KEY_ORDERID] = fileData[index].orderId;
        }

        filesToShow.push(fileAux);
      });
    }
    return filesToShow;
}

  /**
   * @description Convert base64 encoding to Blob
   * @param {string} b64Data Base64 to be decoded
   * @param {RegExp} regex Regular expression for MIME removing
   * @returns {Blob} Blob object
   */
  public base64toBlob (b64Data: string, regex?: RegExp): Blob {
    const _regex = regex ||  /^data:application\/(pdf);base64,/;
    const contentType = '';
    const sliceSize = 512;
    const byteCharacters = atob(b64Data.replace(_regex, ''));
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
}
