import { NgxPrintModule } from 'ngx-print';

import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '../material/material.module';
import { MiniCarouselComponent } from '../../components/mini-carousel/mini-carousel.component';
import { OrderLabelViewComponent } from '../../components/order-label-view/order-label-view.component';
import { ProofDeliveryComponent } from '../../components/proof-delivery/proof-delivery.component';
import { ShowFullImageComponent } from '../../components/dialog/show-full-image/show-full-image.component';

const sharedComponents = [
  MiniCarouselComponent,
  OrderLabelViewComponent,
  ProofDeliveryComponent,
  ShowFullImageComponent
]

const sharedModules = [
  CommonModule,
  FlexLayoutModule,
  FormsModule,
  ReactiveFormsModule,
  MaterialModule,
  NgxPrintModule
]

const exportsModules = [
  ...sharedModules, ...sharedComponents
];

@NgModule({
  declarations: sharedComponents,
  imports: [...sharedModules],
  exports: [...exportsModules, TranslateModule],
})

export class SharedModule { }