import { Component, OnInit } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { ScfCommunicationService, ScfNotification, ScfNotificationCenterConfig } from 'scf-library';

import { filter, pairwise } from '../../node_modules/rxjs/operators';

const SOLUTION = 'tep';

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.scss']
})
export class PagesComponent implements OnInit {
  public solution: string;
  public currentRoute: string;
  public previousRoute: string;

  constructor(
    private router: Router,
    private scfComService: ScfCommunicationService,
  ) {
    this.solution = SOLUTION;
  }

  public ngOnInit(): void {
    this.subscribeRoutes();
    this.subscribeToScfEvents();
  }

  /**
   * @description Subscribes to the Router Service and switches current and previous route accordingly
   * @return {void}
   */
  private subscribeRoutes(): void {
    this.router.events
      .pipe(filter(event => event instanceof RoutesRecognized))
      .pipe(pairwise())
      .subscribe((events: any[]) => {
        this.currentRoute = events.pop().url;
        this.previousRoute = events.pop().url;
      });
  }


  /**
   * @description Subscribe a component to SCF Events
   */
  private subscribeToScfEvents(): void {
    this.scfComService.navigateToSubscribe()
      .subscribe((routerLink: string) => {
        if (routerLink) {
          this.router.navigate([routerLink]);
        }
      });
  }

}
