export enum DialogFilePreviewLabels {
  error = 'Error al recuperar el archivo',
  errorDownloadFile = 'Error al descargar el archivo',
  incidenceDate = 'Fecha de Incidencia: ',
  incidenceDesc = 'Descripción: ',
  incidenceType = 'Tipo:',
  infoDate = 'Fecha de Evidencia: ',
  infoFolio = 'Folio: ',
  infoShipment = 'Embarque: ',
  informationOrigin = 'Origen de Evidencia: ',
  noInfo = 'Sin Información',
  pdfPrefix = 'PlanCarga',
  savingChanges = 'Guardando cambios realizados a la imagen'
}
